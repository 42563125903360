var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "switch_group_box" },
    _vm._l(_vm.switchGroup, function (item, index) {
      return _c(
        "div",
        { key: index, staticClass: "switch_child" },
        [
          _c("switchForm", {
            attrs: { "switch-title": item.switchTitle, type: item.type },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }